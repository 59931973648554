@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  font-family: 'Inter', sans-serif;
}

header {
  z-index: 10001 !important;
}

.btn-primary {
  @apply px-7 py-2 bg-yellow-300 text-white rounded hover:bg-NeutralDGrey transition-all duration-300 hover:-translate-y-4;
}
